@import "../appStyle";

$square-length: calc(100vw / 2 - 34px);

.main {
    height: 100%;
    width: 100%;

    &.landscape {
        max-width: 500px;
    }
}

.main-container {
    height: fit-content;
    min-height: calc(100% - 40px);
    width: calc(100% - 40px);
    padding: 20px;
    display: flex;
    flex-direction: column;

    .big-text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        &.use-promo {
            gap: 24px;
        }
    }

    &.with-header {
        margin-top: 44px;
        min-height: calc(100% - 84px);
    }

    &.gamification-mode {
        text-align: center;

        .small-text {
            display: flex;
            flex-direction: column;
            gap: 10px;
            align-items: center;
        }

        ul {
            text-align: left;
            margin: 0;
        }
    }

    .info-text {
        margin-bottom: 10px;
    }

    .terms-container {
        margin-top: 30px;
        display: flex;
        flex-direction: column;
        gap: 10px;

        ul, ol {
            margin: 0;
        }

        a {
            color: $link-color;
        }
    }

    &.view-shares {
        text-align: center;

        .info-text {
            color: $border-color;
        }

        .social-media-links {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 20px;
            justify-content: center;
            margin: 20px 0 30px;

            .social-media-card {
                width: calc(100% - 36px);
                border: 1px solid $bg-light-grey;
                box-shadow: 1px 1px 5px -0.5px $border-color;
                border-radius: 8px;
                background-color: $white;
                display: flex;
                gap: 20px;
                justify-content: flex-start;
                align-items: center;
                text-align: left;
                padding: 10px 18px;

                .social-media-name {
                    flex-grow: 1;
                }
            }

            img {
                height: 50px;
                width: 50px;
            }
        }

        .image-gallery {
            margin-top: 15px;
        }

    }
}

.landing-page, .thank-you-page {
    display: flex;
    flex-direction: column;
    height: 100%;

    .main-container {
        flex-grow: 1;
        min-height: 0;
        padding-bottom: 0;

        .big-text {
            margin: 16px 0 32px;
        }
    }

    .styled-list {
        margin: 0;
    }

    .bottom-section {
        padding-bottom: 16px;
        margin-top: 20px;

        .styled-button {
            height: inherit;
        }
    }
}

.landing-page {
    .cgc-cover-container {
        height: 272px !important;
        width: 100% !important;
    }

    img, .cgc-cover video {
        width: 100% !important;
        height: 100% !important;
        object-fit: cover;
    }
}

.thank-you-page {
    align-items: center;
    margin-top: 44px;
    padding-bottom: 75px;
    min-height: calc(100% - 84px);

    &.reward-redemption {
        margin-top: 66px;
    }

    .thank-you-date {
        font-weight: 500;
        color: $border-color;
        margin-top: 14px;
    }

    .btn-container {
        width: calc(100% - 32px);
        display: flex;
        flex-direction: column;
        gap: 15px;
        padding-bottom: 72px;

        &.gamification-mode {
            width: calc(100% - 64px);
            gap: 20px;
        }
    }

    .improvements-needed-page {
        .btn-container {
            padding-bottom: 0;
        }
    }

    .thank-you-media-container {
        max-width: 265px;
        position: relative;
        margin-top: 15px;

        img {
            max-width: 265px;
        }

        .qr-code {
            width: 80px;
            height: 80px;
            position: absolute;
            right : 10px;
            bottom: 15px;
        }
    }

    .main-container {
        align-items: center;
        padding-left: 0;
        padding-right: 0;
        width: 100%;

        .big-text {
            text-align: center;
            max-width: 320px;
            padding: 0 16px;
        }

        .additional-text {
            max-width: 320px;
            padding: 0 32px;
            margin-bottom: 30px;
            line-height: 148%;

            &.tips-container {
                max-width: none;
                padding: 15px;
                margin-bottom: 32px;
            }

            &.forgot-to-add-contact {
                margin-bottom: 75px;
            }
        }

        .try-again-message {
            padding: 30px 16px 72px;

            .variable-sentence {
                margin-bottom: 15px;
            }
        }

        .svg-container {
            height: 32px;
            width: 32px;
        }

        .small-text-regular.thank-you-text {
            text-align: center;
            margin: 16px 0 16px;
            width: calc(100% - 32px);
            padding: 0 16px;
        }

        .tips-container {
            margin: 16px;
        }

        .bottom-section {
            width: calc(100% - 32px);
            display: flex;
            flex-direction: column;
            gap: 16px;
            align-items: center;
            text-align: center;
            padding: 32px 16px;
            background-color: $bg-light-grey;
            margin-top: auto;
            flex-grow: 0.5;
            justify-content: flex-end;

            .additional-text {
                font-weight: 500;
            }

            .variable-sentence {
                display: contents;
            }
        }
    }
}

.app-header {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: $white;
    z-index: 100;

    .header-content {
        height: 43px;
        padding: 0 10px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .MuiIconButton-root {
            padding: 0;
        }

        svg {
            height: 18px;
            width: 18px;
            color: $black;
        }

        :nth-child(2) {
            flex-grow: 1;
            display: flex;
            justify-content: center;
        }

        .header-link {
            font-size: 14px;
            font-weight: 500;
            color: #007aff;
        }
    }

    .dividing-line {
        height: 1px;
        background-color: $black;
    }
}

.landscape {
    .landing-page {
        .styled-button {
            position: inherit;
        }
    }

    .keep-snapping {
        .image-gallery {
            justify-content: flex-start;
        }
    }
}

.full-width-btn {
    width: 100%;
}

.white-text {
    color: $white !important;
}

.logo-name {
    font-size: 40px;
    line-height: 144%;
    font-weight: 600;
}

.keep-snapping {

    .link-underline {
        margin-top: -7px;
    }

    .reward-sentence {
        margin-bottom: 15px;
    }
}

.tips-container {
    background-color: $base-red-ten-percent;
    padding: 15px;
    margin-bottom: 32px;

    ul {
        padding-inline-start: 25px;
        margin-block-end: 5px;
    }
}

.image-gallery {
    position: relative;
    z-index: 1;
    display: flex;
    width: 100%;
    gap: 20px;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 32px;
}

.main-container {

    .bottom-section {
        margin-top: 45px;
        display: flex;
        flex-direction: column;
        gap: 32px;

        .variable-sentence {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            gap: 5px;
        }
    }

    &.gamification-mode {
        .big-text {
            margin-bottom: 14px;
        }

        .bottom-section {
            margin-top: 20px;
        }
    }
}

.quick-tips-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;

    .quick-tips-img {
        width: 120px;
        margin-bottom: 7px;
    }

    .quick-tip-box {
        background-color: $base-red-ten-percent;
        padding: 12px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 7px;
        margin-bottom: 7px;
        width: calc(100% - 24px);

        .quick-tip-header {
            font-weight: 500;
        }

        .quick-tip-content {
            font-weight: 300;
        }
    }
}

.media-box {
    width: $square-length;
    height: $square-length;
    max-width: 210px;
    max-height: 210px;
    position: relative;

    img {
        width: $square-length;
        height: $square-length;
        max-width: 210px;
        max-height: 210px;
        object-fit: cover;
    }

    .MuiIconButton-root {
        position: absolute;
        right: 0;
        bottom: 0;
        margin-bottom: 4px;
        margin-right: 4px;
        background-color: $base-red;
        border-radius: 4px;
        height: 32px;
        width: 32px;
        color: $white;

        &.copy-icon {
            padding: 0;
        }

        :hover {
            background-color: $base-red;
            border-radius: 4px;
        }

        svg {
            height: 30px;
            width: 30px;
        }
    }
}

.share-more {
    .input-container {
        gap: 20px;
    }

    .link-underline {
        margin-top: inherit;
    }
}

.input-container {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-bottom: 32px;
}

.terms-and-conditions {

}

.faq {
    gap: 32px;

    .q-a-container {
        display: flex;
        flex-direction: column;
        gap: 4px;
    }
}

.styled-notification.images-rejected-modal {
    max-width: 280px;
    padding: 20px;
}

.styled-notification.redeem-popup-modal {
    width: calc(100vw - 80px);
}

.video-nudge-text, .images-rejected-text, .redeem-popup-text {
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.invisible-caret {
    width: 18px;
}

.upload-media-modal.styled-notification {
    text-align: center;
    gap: 24px;
    width: inherit;
    padding: 24px;

    .styled-notification-btns {
        flex-direction: column;
        margin: 0 36px;

        button {
            background-color: #FFFFFF;
            color: #C12F5D;
            border: 1px solid #C12F5D;
        }

        .image-select-btn-text {
            display: flex;
            gap: 10px;
        }
    }
}