$base-red: #E85B81;
$base-red-ten-percent: #E85B811A;
$emphasized-red: #C12F5D;
$black: #1A141F;
$hint-text-color: #4B3A5A;
$border-color: #ABA7AF;
$disabled-color: #D4D2D5;
$spacer-color: #D9D1E0;
$spacer-color-light: #E5E0EB;
$bg-light-grey: #F5F3F7;
$white: #FFFFFF;
$error-color: #D51A52;
$attention-color: #FF9500;
$approval-color: #00B998;
$link-color: #5340C3;

.bold {
    font-weight: 700;
}

.hint {
    color: $hint-text-color;
}

.minor-text {
    color: $border-color;
}

.small-text-regular {
    font-weight: 400;
    font-size: 16px;
    line-height: 148%;
}

.small-text {
    font-size: 16px;
    line-height: 148%;
    font-weight: 300;
}

.additional-text {
    font-size: 14px;
    line-height: 140%;
    font-weight: 400;
    font-family: Poppins, sans-serif;
}

.middle-text {
    font-size: 18px;
    line-height: 132%;
    font-weight: 500;
}

.middle-text-light {
    font-size: 18px;
    line-height: 132%;
    font-weight: 300;
}

.big-text {
    font-size: 20px;
    line-height: 144%;
    font-weight: 500;
}

.h1 {
    font-size: 29px;
    line-height: 124%;
    font-weight: 600;
}

.h2 {
    font-size: 22px;
    line-height: 128%;
    font-weight: 600;
}

.h3 {
    font-size: 19px;
    line-height: 128%;
    font-weight: 700;
}

.h4 {
    font-size: 16px;
    line-height: 128%;
    font-weight: 700;
}

.tablet {
    .h1 {
        font-size: 32px;
        font-weight: 500;
    }

    .h2 {
        font-size: 24px;
        font-weight: 500;
    }

    .h3 {
        font-size: 22px;
        line-height: 124%;
        font-weight: 600;
    }

    .h4 {
        font-size: 18px;
        line-height: 132%;
    }
}

.desktop {
    .h1 {
        font-size: 44px;
        line-height: 128%;
        font-weight: 500;
    }

    .h2 {
        font-size: 30px;
        line-height: 120%;
        font-weight: 500;
    }

    .h3 {
        font-size: 22px;
        line-height: 124%;
        font-weight: 600;
    }

    .h4 {
        font-size: 18px;
        line-height: 132%;
    }
}
