@import "typographyStyle";

.styled-list {
    list-style-type: none;
    padding-left: 20px;

    li {
        margin-bottom: 8px;
        position: relative;
    }
}

ul.styled-list {
    li {
        &::before {
            content: '';
            position: absolute;
            left: -20px;
            top: 0.5em; // vertically center the bullet
            width: 8px;
            height: 8px;
            background-color: $base-red;
            border-radius: 2px;
        }
    }
}

ol.styled-list {
    li {
        &::before {
            content: counter(list-item) ". ";
            position: absolute;
            left: -20px;
            text-align: right;
            font-weight: 700;
            color: $base-red;
        }
    }
}

.styled-quotation {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;

    .quotation-bracket {
        align-self: stretch;
        width: 4px;
        background-color: $base-red;
    }

    .quotation-main {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 4px 0;
        gap: 4px;

        .quotation-text {
            align-self: stretch;
        }
    }
}

.styled-link {
    width: fit-content;
    color: $link-color;

    .link-underline {
        position: relative;
        align-self: stretch;
        border-top: 1px solid $link-color;
        box-sizing: border-box;
        height: 1px;
        width: 100%;
        margin-top: -3px;
    }

    &:hover {
        color: $black;

        .link-underline {
            border-top: 1px solid $black;
        }
    }
}

.styled-button {
    background: none;   //remove native button style
    color: inherit;
    border: none;
    font: inherit;
    cursor: pointer;
    outline: inherit;

    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 12px 52px;
    text-align: center;

    &.main {
        background-color: $base-red;
        color: $white;
    }

    &.secondary {
        background-color: $white;
        color: $emphasized-red;
        border: 1px solid $emphasized-red;
    }

    &.text {
        color: $emphasized-red;
        padding: 0;
    }

    &:hover {
        &.main {
            background-color: $emphasized-red;
        }

        &.secondary {
            background-color: $bg-light-grey;
        }

        &.text {
            color: $black;
        }
    }

    &.disabled {
        &.main {
            background-color: $disabled-color;
        }

        &.secondary {
            background-color: $white;
            color: $disabled-color;
            border: 1px solid $disabled-color;
        }

        &.text {
            color: $disabled-color;
        }
    }

    &.no-border {
        border: none;
    }
}

.styled-input {
    width: 100%;

    .input-header {
        margin-bottom: 12px;
    }

    textarea {
        padding: 12px;
        width: calc(100% - 24px);
        border: 1px solid $border-color;
        border-radius: 2px;
        background-color: $white;

        &.has-error {
            border-color: $error-color;

            &:focus {
                box-shadow: 0 0 3px $error-color;
            }
        }

        &:focus {
            outline: none;
            border: 1px solid $hint-text-color;
        }

        ::-webkit-resizer {
            color: $border-color;
        }
    }

    .error-message {
        color: $error-color;
        text-align: right;
    }
}

.styled-select {
    width: 100%;

    .select-header {
        margin-bottom: 12px;
    }

    .custom-select {
        padding: 12px;
        width: calc(100% - 24px);
        border: 1px solid $border-color;
        border-radius: 2px;
        background-color: $white;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        transition: border-color 0.3s ease;

        &.has-error {
            border-color: $error-color;

            &:focus-within {
                box-shadow: 0 0 3px $error-color;
            }
        }

        &:focus-within {
            outline: none;
            border: 1px solid $hint-text-color;
        }

        .select-display {
            flex-grow: 1;
            color: $black;

            &.placeholder {
                color: $border-color;
            }
        }

        .arrow {
            width: 0;
            height: 0;
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
            border-top: 6px solid $black;
            transition: transform 0.3s ease;

            &.open {
                transform: rotate(180deg);
            }
        }
    }

    .select-dropdown {
        position: relative;
        width: 100%;
        top: calc(100% + 5px);
        left: 0;
        background-color: $white;
        border: 1px solid $border-color;
        border-radius: 2px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        z-index: 1000;
        max-height: 200px;
        overflow-y: auto;

        .select-option {
            padding: 12px;
            cursor: pointer;
            color: $black;
            transition: background-color 0.2s ease;

            &:hover {
                background-color: $border-color;
            }

            &.selected {
                background-color: $base-red;
                color: $white;
            }
        }
    }

    .error-message {
        color: $error-color;
        text-align: right;
        margin-top: 8px;
        font-size: 12px;
    }
}

.styled-checkbox-holder {
    line-height: 30px;

    label {
        display: flex;
        gap: 12px;
        align-items: center;
        position: relative;
        cursor: pointer;

        input {
            appearance: none;
            -webkit-appearance: none;
            margin: 0;
            border: 1px solid $spacer-color;
            border-radius: 4px;
            width: 18px;
            height: 18px;
            position: relative;
            display: flex;
            align-content: center;
            justify-content: center;
            font-size: 2rem;

            &:checked {
                background-color: $base-red;
                border-color: $base-red;
            }
        }

        .checkmark {
            position: absolute;
            left: 6px;
            top: 25%;
            width: 4px;
            height: 8.5px;
            border: solid $white;
            border-width: 0 3px 3px 0;
            border-radius: 1px;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
        }
    }
}

.styled-upload {
    $square-length: calc(100vw / 2 - 30px);
    width: $square-length;
    height: $square-length;
    max-width: 210px;
    max-height: 210px;
    border: 2px dashed $border-color;
    border-radius: 4px;
    background-color: $bg-light-grey;
    display: flex;
    justify-content: center;
    align-items: center;

    .upload-btn {
        line-height: 0;
    }

    img {
        height: 44px;
    }
}

.styled-snackbar {
    background: $white;

    //.MuiAlert-root {
    //    border-color: $border-color;
    //}

    .MuiAlert-icon {
        align-items: center;
    }
}

.styled-modal {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: $white;
    padding: 12px;
}

.styled-notification {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    width: calc(100vw - 40px);
    max-width: 330px;

    .styled-notification-btns {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
        flex-wrap: wrap;

        .styled-button {
            white-space: nowrap;
            padding-left: 42px;
            padding-right: 42px;
        }
    }
}

.video-recorder {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1301;
    background: $black;
    display: flex;
    justify-content: center;
    align-items: center;

    .controls {
        position: absolute;
        bottom: 100px;
        z-index: 1302;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 50px;
        padding: 10px;

        .video-btn {
            background: $white;
            border-radius: 50%;
            padding: 0;
        }

        .video-recorder-toggle-camera {
            position: fixed;
            left: 10px;
            top: 10px;
        }
    }

    .camera-loading {
        position: absolute !important;
        bottom: 50px;
        z-index: 1304;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .video-recorder-close-btn {
        position: absolute;
        top: 10px;
        right: 10px;
        z-index: 1302;
    }
}

.styled-radio-group {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;

    .styled-radio-btn-container {
        width: 100%;
    }

    .styled-radio-sub-content {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        margin: 8px 0;
    }
}

.styled-radio-btn {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    .styled-radio-circle {
        height: 16px;
        width: 16px;
        margin-right: 12px;
        border: $border-color 1px solid;
        border-radius: 50%;
        flex-shrink: 0;

        &.selected {
            height: 6px;
            width: 6px;
            border: $base-red 6px solid;

            &.disabled {
                border-color: $border-color;
                background-color: #aba7af;
            }
        }

        &.disabled {
            background-color: $border-color;
        }
    }
}

.styled-dropdown-menu {
    position: relative;
    display: inline-block;

    .menu-button {
        background: none;
        border: none;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 8px;
        border-radius: 4px;
        transition: background-color 0.3s ease;

        &:hover {
            background-color: rgba(0, 0, 0, 0.05);
        }
    }

    .menu-dropdown {
        position: absolute;
        top: 100%;
        left: 50px;
        transform: translateX(-50%);
        background-color: $white;
        border: 1px solid $border-color;
        border-radius: 4px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        z-index: 1000;
        min-width: 150px;
        max-width: 250px;
        max-height: 200px;
        overflow-y: auto;

        .menu-option {
            padding: 12px 16px;
            cursor: pointer;
            display: flex;
            align-items: center;
            gap: 8px;
            color: $black;
            transition: background-color 0.2s ease, color 0.2s ease;

            &.delete {
                color: $error-color;
            }

            &:hover {
                background-color: var(--hover-bg, $base-red);
                color: var(--hover-text, $white);

                .event-post-menu-option {
                    svg {
                        fill: var(--hover-text, $white);
                    }
                }
            }

            &:active {
                background-color: color-mix(in srgb, var(--hover-bg, $base-red) 80%, black 20%);
                color: var(--hover-text, $white);

                .event-post-menu-option {
                    svg {
                        fill: var(--hover-text, $white);
                    }
                }
            }
        }
    }
}